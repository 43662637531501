// import React, { useState, useEffect } from 'react'

// import { Link, NavLink } from 'react-router-dom'
// import menus from '../../pages/menu'
// import Button from '../button/index'
// import './styles.scss'
// import logo from '../../assets/images/logo/logo.png'
 import { _getChain, useWalletHook ,connectMetamask,switchToMainnet,IsBscNetAndWalletHas} from '../../utils/ethereum.tsx'
  import ConnectWallet from "../../pages/ConnectWallet";
// import { useTranslation } from '../../utils/contexts/Localization'

import { useWalletModal } from '@pancakeswap/uikit'


import LangSelector from "../../utils/LangSelector";
import {languageList} from "../../config/localization/languages";
 
// export default Header
import React, { useState } from 'react';
import logo from '../../assets/images/logo/logo.png'
import { NavLink } from 'react-router-dom';
import { useTranslation } from '../../utils/contexts/Localization'
function Header() {
  const { t } = useTranslation()
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = (e) => {
    if (window.innerWidth < 992 && !e.target.classList.contains('menu-item-has-children')) {
      setIsMenuOpen(false);
    }
  };

  const  walletInfo =  useWalletHook();

  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(
    () => null,
    () => null,
    walletInfo.wallet
  );


  const connectInfo = ()=>{
    console.log("数据123",    walletInfo.wallet)
    if(walletInfo.wallet){
      onPresentConnectModal()
    /*   IsBscNetAndWalletHas() */
     /*  connectMetamask(); */
    
    }else{
     // switchToMainnet()
    }
   
    
  }

  return (
    <header className="header-section" >
      <div className="header-bottom">
        <div className="container">
          <div className="header-wrapper">
            <div className="logo">
              <a href="index.html">
                <img src={logo} alt="logo" />
              </a>
            </div>
            <div className={`menu-area `}>
              <ul className={`menu ${isMenuOpen ? 'active' : ''}`} onClick={handleMenuItemClick}>
                <li>
                 {/* <a href="#0">Pages</a> */}
                 <NavLink to="/">
                   {/* <a href="#0">Mint</a> */}
                   <span>{t('Home')}</span>
                  </NavLink>
                </li>
                <li>
                  <a href="/#about">{t('About')}</a>
                </li>
                <li>
                  <a href="/#team">{t('Team')}</a>
                </li>
                <li>
                  <a href="/#faq">{t('FAQ')}</a>
                </li>
                <li>
               

                  <NavLink to="/project_v2">
                   {/* <a href="#0">Mint</a> */}
                   <span>{t('Market')}</span>
                  </NavLink>
                </li>
                <li>
                  {/* <a href="#0">Pages</a> */}
                  <NavLink to="/project_v1?id=1">
                   {/* <a href="#0">Mint</a> */}
                   <span>{t('Mint')}</span>
                  </NavLink>
                </li>
              </ul>
              <LangSelector
              currentLang="zh"
              langs={languageList} 
              dropdownPosition="top-right"
            />
              <div className="header-btn">
                <a href="https://discord.gg/jfUq9jjj8b" target={"_blank"} className="default-btn default-btn--secondary">
                  <span>Join <i className="fa-brands fa-discord"></i></span>
                </a>

                
                <a href="#"  onClick={connectInfo} style={{background:walletInfo.wallet?"":"#b9b9b9"}} className="default-btn contents" data-bs-toggle="modal" data-bs-target="#wallet-option" >
                  <span> 
                    <span class="phone_span" >{walletInfo.wallet?"0x..."+walletInfo.wallet.substring(38,42):"Connect"}
                    </span> <i className="fa-solid fa-wallet"></i></span>
                </a>

                
              </div>
              {/* <div className="cheader-bar d-lg-none " onClick={handleMenuToggle}> */}
              <div className={`header-bar d-lg-none ${isMenuOpen ? 'active' : ''}`} onClick={handleMenuToggle}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
