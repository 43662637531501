/*
 * @Author: ahsdxy 2729945316@qq.com
 * @Date: 2024-05-06 12:21:05
 * @LastEditors: ahsdxy 2729945316@qq.com
 * @LastEditTime: 2024-05-09 23:52:40
 * @FilePath: \wasai-nft-web\src\components\pagehtml\CommunitySection.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import '../../assets/images/favicon.png';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/aos.css';
import '../../assets/css/all.min.css';
import '../../assets/css/lightcase.css';
import '../../assets/css/swiper-bundle.min.css';
import '../../assets/css/style.css';
import join from "../../assets/images/opensea.svg";
import { useTranslation } from '../../utils/contexts/Localization'
import { Constants  } from '../../utils/Config'



function CommunitySection() {
  const { t } = useTranslation()
  return (
    <section className="community padding-top padding-bottom" style={{backgroundImage: "url(" + require('../../assets/images/community/bg.jpg') + ")"}}>
      <div className="container">
        <div className="comminity__wrapper">
          <div className="section-header text-center">
            <p className="subtitle">{t('Our Community')}</p>
            <h2>{t('Join Our Community and get early access')}</h2>
          </div>
          <div className="btn-group justify-content-center">
            <a  href="https://discord.gg/jfUq9jjj8b" target={"_blank"} className="default-btn default-btn--secondary"><span><i className="fab fa-discord"></i> {t('Join Discord')}</span></a>
            <a href={Constants.OPENSEA_URL} className="default-btn" target={"_blank"} > <span><img src={join} alt="opensea icon" width="20" height="20"/> {t('Join Opensea')}</span> </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CommunitySection;
