/*
 * @Author: ahsdxy 2729945316@qq.com
 * @Date: 2024-05-06 12:19:21
 * @LastEditors: ahsdxy 2729945316@qq.com
 * @LastEditTime: 2024-05-16 10:27:18
 * @FilePath: \wasai-nft-web\src\components\pagehtml\BlogSection.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from "react";
import '../../assets/images/favicon.png';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/aos.css';
import '../../assets/css/all.min.css';
import '../../assets/css/lightcase.css';
import '../../assets/css/swiper-bundle.min.css';
import '../../assets/css/style.css';
import { useTranslation } from '../../utils/contexts/Localization'
import { NavLink } from "react-router-dom";
import '../../assets/css/style.css'
function BlogSection() {
  const { t } = useTranslation()
  return (
    <section
      className="blog padding-top padding-bottom m100"
      style={{ backgroundImage: "url("+require('../../assets/images/blog/bg.jpg') + ")", }}
    >
      <div className="container" id="1container">
        <div className="section-header text-center">
          <p className="subtitle">{t('Basic concepts')}</p>
          <h2>{t('Our Recent Post')}</h2>
        </div>
        <div className="blog__wrapper">
          <div className="row g-4">
            {/* <NavLink to='/project_v5'> */}
            <div className="col-lg-4 col-md-6">
              <div className="blog__item">
                <div className="blog__inner">
                  <div className="blog__thumb">
                    <img src={require('../../assets/images/blog/01.jpg')} alt="Blog Images" />
                  </div>
                  <div className="blog__content">
                    <div className="blog__content-top">
                      <span className="blog__meta-tag">NFT</span>
                      <h4 style={{fontSize:'22px'}}>
                      <a href="/#1container">
                          {t('Distinct Resource Maximizing Minting')}
                        </a>
                      </h4>
                     
                    </div>
                    <p>
                      {t('He is a unique metadata...')}
                    </p>
                   
                  </div>
                </div>
              </div>
              
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog__item">
                <div className="blog__inner">
                  <div className="blog__thumb">
                    <img src={require('../../assets/images/blog/02.jpg')} alt="Blog Images" />
                  </div>
                  <div className="blog__content">
                    <div className="blog__content-top">
                      <span className="blog__meta-tag">MINTING</span>
                      <h4 style={{fontSize:'22px'}}>
                        <a href="/#1container">
                          {t('Make 100% profit by minting nft')}
                        </a>
                      </h4>
                     
                    </div>
                    <p>
                      {t('A very fair market environment...')}
                    </p>
                   
                  </div>
                </div>
              </div>
              
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog__item">
                <div className="blog__inner">
                  <div className="blog__thumb">
                    <img src={require('../../assets/images/blog/03.jpg')} alt="Blog Images" />
                  </div>
                  <div className="blog__content">
                    <div className="blog__content-top">
                      <span className="blog__meta-tag">Crypto</span>
                      <h4 style={{fontSize:'22px'}}>
                      <a href="/#1container">
                          {t('Purchase your 1st Nft Crypto here')}
                        </a>
                      </h4>
                     
                    </div>
                    <p>
                      {t('Having the best consensus...')}
                    </p>
                   
                  </div>
                </div>
              </div>
              
            </div>
            {/* </NavLink> */}
            
            {/* Repeat the same structure for the other blog items */}
          </div>
          {/* <div className="mt-5 text-center">
            <a href="blog.html" className="default-btn">
              <span>{t('View all blog post')}</span>
            </a>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default BlogSection;
