/*
 * @Author: ahsdxy 2729945316@qq.com
 * @Date: 2024-05-06 12:21:51
 * @LastEditors: ahsdxy 2729945316@qq.com
 * @LastEditTime: 2024-05-06 23:22:35
 * @FilePath: \wasai-nft-web\src\components\pagehtml\Footer.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import '../../assets/images/favicon.png';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/aos.css';
import '../../assets/css/all.min.css';
import '../../assets/css/lightcase.css';
import '../../assets/css/swiper-bundle.min.css';
import '../../assets/css/style.css';
function Footer() {
  return (
    <footer className="footer" style={{backgroundImage: "url(" + require('../../assets/images/footer/bg.png') + ")"}}>
      <div className="footer__wrapper padding-top padding-bottom">
        <div className="container">
          <div className="footer__content text-center">
            <a className="mb-4 d-inline-block" href="index.html"><img src={require('../../assets/images/logo/logo.png')} alt="Logo" /></a>
            <ul className="social justify-content-center">
              <li className="social__item">
                <a target={"_blank"} href="https://twitter.com/wasaixingqiu" className="social__link"><i className="fab fa-twitter"></i></a>
              </li>
              <li className="social__item">
                <a target={"_blank"} href="#https://discord.gg/jfUq9jjj8b" className="social__link"><i className="fab fa-discord"></i></a>
              </li>
              <li  className="social__item">
                <a href="#" className="social__link"><i className="fab fa-twitch"></i></a>
              </li>
              <li className="social__item">
                <a href="#" className="social__link"><i className="fab fa-instagram"></i></a>
              </li>
              <li className="social__item">
                <a href="#" className="social__link"><i className="fab fa-linkedin-in"></i></a>
              </li>
              <li className="social__item">
                <a href="#" className="social__link"><i className="fab fa-facebook-f"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer__copyright">
        <div className="container">
          <div className="text-center py-4">
            <p className=" mb-0">© 2024 | All Rights by <a href="Http://www.bootstrapmb.com">wasai</a> </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
