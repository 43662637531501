/*
 * @Author: ahsdxy 2729945316@qq.com
 * @Date: 2024-05-06 10:21:56
 * @LastEditors: ahsdxy 2729945316@qq.com
 * @LastEditTime: 2024-05-06 23:37:33
 * @FilePath: \wasai-nft-web\src\components\pagehtml\WalletModal.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import '../../assets/images/favicon.png';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/aos.css';
import '../../assets/css/all.min.css';
import '../../assets/css/lightcase.css';
import '../../assets/css/swiper-bundle.min.css';
import '../../assets/css/style.css';
import { useTranslation } from '../../utils/contexts/Localization'
function WalletModal() {
  const { t } = useTranslation()
  return (
    <div className="wallet-modal modal fade" id="wallet-option" tabIndex="-1" aria-labelledby="choose-wallet" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="choose-wallet">{t('Connect Your Wallet')}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <p>Please select a wallet to connect for <br /> Start Minting your NFTs</p>
            <ul className="wallet__list">
              <li className="wallet__list-item"><a href="#"> <span><img src={require('../../assets/images/wallet/metamask.jpg')} alt="metamask" /></span> </a></li>
              <li className="wallet__list-item"><a href="#"> <span><img src={require('../../assets/images/wallet/coinbase.jpg')} alt="coinbase" /></span> </a></li>
              <li className="wallet__list-item"><a href="#"> <span><img src={require('../../assets/images/wallet/bsc.jpg')} alt="bsc" /></span></a></li>
              <li className="wallet__list-item"><a href="#"> <span><img src={require('../../assets/images/wallet/trust.jpg')} alt="Trust Wallet" /></span></a></li>
            </ul>
            <p>By connecting your wallet, you agree to our Terms of Service and our Privacy Policy.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WalletModal;
