import React from 'react'
import styled from 'styled-components'
// import Text from "../../../components/Text/Text";
// import Dropdown from "../../../components/Dropdown/Dropdown";
// import Button from "../../../components/Button/Button";
// import LanguageIcon from "../../../components/Svg/Icons/Language";
// import { Language } from "../types";
// import MenuButton from "./MenuButton";

import { Text, Dropdown,DropdownProps, Button, LanguageIcon, Language } from '@pancakeswap/uikit'
 import { useTranslation } from './contexts/Localization'


const MenuButton = styled(Button)`
  color: ${({ theme }) => theme.colors.text};
  // padding: 0 8px;
  border-radius: 8px;
`
MenuButton.defaultProps = {
  variant: 'text',
  size: 'sm',
}

interface Props {
  currentLang: string
  langs: Language[] 
}
const dropdownProps = {
  children: [],
  position: "bottom",
  
  target:  <Button variant="text" p={1} startIcon={<LanguageIcon color="textSubtle" width="20px" />}>
  <Text color="textSubtle">{ "EN"}</Text>
</Button>, // 此处放置合适的组件
} as DropdownProps;


const LangSelector: React.FC<Props> = ({ currentLang, langs }) => {
  const {  setLanguage, t } = useTranslation() 

  
  const curr =     localStorage.getItem('pancakeswap_languageS')

  console.log("curr",curr);


  dropdownProps.target = <Button variant="text" p={1} startIcon={<LanguageIcon color="textSubtle" width="20px" />}>
  <Text color="textSubtle">{curr?curr.toUpperCase():currentLang?.toUpperCase()}</Text>
</Button>
  return (

   
    <div style={{marginRight:'20px',position:'relative',zIndex:9999999999999999}}>
      <Dropdown
    
    /*  position="top-right"
     target={
       <Button variant="text" p={1} startIcon={<LanguageIcon color="textSubtle" width="20px" />}>
         <Text color="textSubtle">{currentLang?.toUpperCase()}</Text>
       </Button>
     } */
     {...dropdownProps}
   >
     {langs.map((lang) => (
       <MenuButton
         key={lang.locale}
         fullWidth
         onClick={ ()=>{setLanguage(lang)}  }
         // Safari fix
         style={{ minHeight: '32px', height: 'auto' }}
       >
         {lang.language}
       </MenuButton>
     ))}
   </Dropdown>
    </div>  
  )
}

export default React.memo(LangSelector, (prev, next) => prev.currentLang === next.currentLang)
