import React, { useState ,useEffect} from 'react'

import PageTitle from '../components/pagetitle'
import CTA from '../components/cta/cta_v2'
import { Link } from 'react-router-dom'
 

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import {
  Box,
  alertVariants,
  Alert, 
  Button,Input,AddIcon,RemoveIcon
} from '@pancakeswap/uikit'
import data from '../assets/fake-data/data-project'
import {getContractHandler,_getAddress,useWalletHook} from '../utils/ethereum'
import {Constants} from '../utils/Config'
import useToast from '../utils/useToast'
import {ToastContainer} from '../utils/Toast'

import CopyAddress from '../utils/CopyAddress'

import { BigNumber, Contract, providers, utils } from 'ethers'
 function Project1(props) {
  const [dataTab] = useState([
    {
      id: 1,
      title: 'MINT Wasai',
    },
   
  ])

  const [parent,setParent] = useState(0)
  const [numbe,setNumbe] = useState("0")
  const [buyNumber,setBuyNumber] = useState(1)
  const [toAddress,setToAddress] = useState("")
 



  const { toastSuccess, toastError } = useToast()
  const env = useWalletHook()
  const queryMyNFT=async ()=>{
    const WasaiNFT = await getContractHandler('WasaiNFT')
    if(!WasaiNFT) return;

    const total = await WasaiNFT.totalSupply()

    setNumbe(utils.formatUnits(total,0)); 
    setParent(Number(utils.formatUnits(total,0) /150))
    
  }

  useEffect(  () => {
    if(env){ 
      queryMyNFT();
    }
        
  }, [env]);

 
  




  const buyUseUSDT= async ()=>{

    try {
  
      const usdt = await getContractHandler('USDT')
      const WasaiMint = await getContractHandler('WasaiMint')

      const account  =await _getAddress() 

      console.log("adddddd",toAddress)
 
      await WasaiMint.mintBlindBox(toAddress,buyNumber )
      toastSuccess('Successfully submit Mint!')
       
    } catch (e) {
      
    toastError(e?.message)
      console.error(e?.message)
    }

  }


   




  return (
    <div className="inner-page">
      {<PageTitle />}
 

   

      <section className="tf-section project">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="flat-tabs"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <Tabs>
                  <div className="wrapper_menutab">
                    <TabList className="menu-tab">
                      {dataTab.map((idx) => (
                        <Tab className="fs-14 h6" key={idx.id}>
                          {idx.title}
                        </Tab>
                      ))}
                    </TabList>
                    <div
                      className="seclect-box"
                      data-aos="fade-in"
                      data-aos-duration="800"
                    >
                        
                    </div>
                  </div>

                  {dataTab.map((idx) => (
                    <TabPanel key={idx.id} className="content-tab mt40">
                      <div
                        style={{ margin: 'auto' }}
                        className="content-inner project-box-style6_wrapper"
                      >
                        <div className="project-box-style6">
                          <span className="boder">批量扫描盲盒，请耐心等待..</span>
                          <div className="img-box">
                            <div className="image">
                              <img
                                style={{ width: '100%' }}
                                src={require('../assets/images/common/box.jpg')}
                                alt=""
                              />
                            </div>
                            <div className="content">
                               
                              <div className="content-bottom">
                               
                                <div className="sub-content">
                                  <RemoveIcon onClick={()=>{
                                    if(buyNumber>1){
                                      setBuyNumber(buyNumber - 1)
                                    }  
                                  }} cursor={"pointer"} />
                                    <Input   value={buyNumber}  style={{width:"60px"}}/>
                                  <AddIcon onClick={()=>{
                                     
                                      setBuyNumber(buyNumber + 10)
                                    
                                  }} cursor={"pointer"}/>
                                </div>

                               
                                <div className="sub-content">
                                    <button type="submit" className="tf-button style2" onClick={buyUseUSDT}>
                                       批量打开
                                    </button> 
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  ))}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>

 
    </div>
  )
}

export default Project1
