import axios from 'axios';  
  
export const API_URL = "https://metainfo.wasai.space"; // 假设您的后端服务运行在这个地址  
  
async function handleUserAddress(action, address, url = null, token = null, selected = null) {  
  try {  
    const response = await axios.post(`${API_URL}/user_address`, {  
      action,  
      address,  
      url,  
      token,  
      selected,  
    });  
    return response.data;  
  } catch (error) {  
    console.error('Error during user address handling:', error);  
    return { message: 'Error occurred', code: '500' };  
  }  
}  
  
export default handleUserAddress;