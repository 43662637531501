import React, { useState, useEffect } from "react";

// import PageTitle from "../components/pagetitle";
// import CTA from "../components/cta/cta_v2";
import { Link } from "react-router-dom";
import "./project.scss";
import { useTranslation } from "../utils/contexts/Localization";
// import Project5 from "../components/project/project_v5";
import ProjectItem from "../components/project/project_detail";
import dataProject from "../assets/fake-data/data-project";
import Blog from '../components/pagehtml/Blog.jsx'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  Box,
  alertVariants,
  Alert,
  Button,
  Input,
  AddIcon,
  RemoveIcon,
  Flex,
  Spinner,
} from "@pancakeswap/uikit";
// import data from "../assets/fake-data/data-project";
// import data from '../assets/fake-data/data-project'
import {
  getContractHandler,
  _getAddress,
  useWalletHook,
  connectMetamask,
  watchTransaction,
  switchToMainnet,
  IsBscNet,
} from "../utils/ethereum";
import { Constants, ImgUrl, IsTest } from "../utils/Config";
import useToast from "../utils/useToast";
import { ToastContainer } from "../utils/Toast";
import video from "../assets/mintvideo.mp4";
import CopyAddress from "../utils/CopyAddress";
import axios from "axios";
import { BigNumber, Contract, providers, utils } from "ethers";
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';


import { ErcType, EvmChain, NftscanEvm } from "nftscan-api";


function Project1(props) {
  const { t } = useTranslation();

  const walletInfo = useWalletHook();

  const changeclolenum = "2";
  const [color, setColor] = useState(changeclolenum);

  const [dataTab] = useState([
    {
      id: 1,
      title: t("MINT Wasai"),
    },
    /* {
      id: 2,
      title: 'Upcoming',
    },
    {
      id: 3,
      title: 'Past IGO',
    }, */
  ]);




  const [parent, setParent] = useState(20);
  const [numbe, setNumbe] = useState("0");
  const [buyNumber, setBuyNumber] = useState(1);

  const [toAddress, setToAddress] = useState();
  const [isApprove, setIsApprove] = useState("Approve");

  const { toastSuccess, toastError } = useToast();


  const [allData, setAlldata] = useState([]);
  const [allDataBox, setAlldataBox] = useState([]);

  const [dataTokens, setDataTokens] = useState([]);
  const [dataTokensBox, setDataTokensBox] = useState([]);

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(6);
  const [total, setTotal] = useState(0);
  const [nowpage, setNowPage] = useState(1);

  const [nowpageV, setNowPageV] = useState();

  const [isLoading, setLoading] = useState(false);

  const [isLoadingPro, setLoadingPro] = useState(false);


  // console.log(res,'res')

  const [data, setData] = useState([]);

  const data8 = dataProject;


  const config = {
    apiKey: "MjedsCgTx21eAcog4cP5yYIz", // Replace with your NFTScan API key.
    chain: IsTest ? EvmChain.BASE : EvmChain.ETH, // Replace with your chain.
  };

  const evm = new NftscanEvm(config);


  const changeNumber = (e) => {
    setNowPageV(e.target.value);
  };

  const env = useWalletHook();
  const queryNFT = async () => {
    const WasaiNFT = await getContractHandler("WasaiNFT");
    if (!WasaiNFT) return;

    const total = await WasaiNFT.totalSupply();

    setNumbe(utils.formatUnits(total, 0));
    setParent(Number(utils.formatUnits(total, 0) / 1000));
  };
  // let { id } = useParams()
  // console.log(id)
  useEffect(() => {


    if (color == 1) {
      axios
        .get(ImgUrl + "/list/" + walletInfo.wallet)
        .then((response) => {
          // 处理成功响应
          // console.log(response.data);


          //检查链上数据


          setData(response.data.data);
        })
        .catch((error) => {
          // 处理错误
          console.error("请求错误：", error);
        });
    }

    /*     if(color==3){
          setTimeout(() => {
            if (env) {
              
              queryTokens();
            }
          }, 1000);
        } */

    if (color == 3) {
      setTimeout(() => {
        if (env) {

        //  queryNFTBalnce()
        }
      }, 1000);
    }

    if (color == 2) {
      setTimeout(() => {
        if (env) {
          try {
            queryNFT();
            setToAddress(walletInfo.wallet);

            approveCheck();
          } catch (error) { }

        }
      }, 1000);
    }


  }, [env.wallet, start, end, color]);

  //查询个人NFT

  const [ownerTotal,setOwnerTotal] = useState(0);

  const queryNFTBalnce=async ()=>{
    const WasaiNFT = await getContractHandler("WasaiNFT");

    console.log("WasaiNFT", WasaiNFT);

    if (!WasaiNFT) return;

    const account = await _getAddress();
    //随机数
    const totals = await WasaiNFT.balanceOf(account);

    setOwnerTotal(Number(utils.formatUnits(totals, 0) ))
  

        
        
  }

  //我的列表
  const queryAllTokens = async () => {
    const WasaiNFT = await getContractHandler("WasaiNFT");

    console.log("WasaiNFT", WasaiNFT);

    if (!WasaiNFT) return;

    const account = await _getAddress();
    //随机数
    //const totals = await WasaiNFT.ERC721TokenIds(account, 0, 100000);


    // 填入你的 API Key、合约地址和钱包地址  
    const contract_address = account; // Replace with the account address you want to query.
    // Access asset-related APIs
    evm.asset
      // Retrieve assets owned by an account.
      .getAssetsByAccount(contract_address, {
        erc_type: ErcType.ERC_721, // Can be erc721 or erc1155
        limit: 1000,
        contract_address: Constants.Contract.WasaiNFT
      })
      .then((res) => {

        console.log("resres", res)
        console.log("res?.data?.data", res?.content)
        if (res?.total) {

          setTotal(res?.total)
        }
        //设置总来给你


        if (res?.content) {
          const queryss = (res.content.map((item) => Number(item.token_id)))
          const boxss = (res.content.map((item) => false))

          console.log("queryss", boxss)
          setAlldata(queryss);
          setAlldataBox(boxss);

          setDataTokens(queryss);
          setDataTokensBox(boxss)
          /* setTimeout(()=>{

            console.log(2222222222222222,res?.content)
            setDataTokens(res?.content?.map(items => item.token_id));
             setDataTokensBox(res?.content?.map(items => false))
          },1000) */

        }


      });

    /*   setTotal(utils.formatUnits(totals.balance._hex, 0));
  
      setAlldata(totals.ids);
      setAlldataBox(totals.boxs);
  */



    setLoadingPro(!isLoadingPro)
  };


  const queryTokens = async () => {
    /* if(allData.length>0){
      setTimeout(()=>{
       setDataTokens(allData.slice(start, end))
       setDataTokensBox(allDataBox.slice(start, end))
       setLoadingPro(!isLoadingPro)
      },1000)
    }else{ */
    queryAllTokens()
    /* } */
  };


  //授权检查
  const approveCheck = async () => {
    const isOk = IsBscNet();
    

    if (!isOk) {
      await switchToMainnet();
    }

    await connectMetamask();

    const account = await _getAddress();

    if(!account){
      return;
    }

    const usdt = await getContractHandler("USDT");

    const approvePidao = await usdt.allowance(
      account,
      Constants.Contract.WasaiNFT
    );

    if (parseFloat(utils.formatEther(approvePidao)) > 0) {
      setIsApprove("BuyBox");
    }
  };

  const buyUseUSDT = async () => {
    try {
      //开始 Loading
      setLoading(true);

      const isOk = IsBscNet();

      if (!isOk) {
        await switchToMainnet();
      }

      await connectMetamask();

      const usdt = await getContractHandler("USDT");
      const WasaiMint = await getContractHandler("WasaiNFT");
      console.log(usdt, "wasaiusdt");
      if (!usdt || !WasaiMint) {
        console.log("加载失败");
        return;
      }

      const account = await _getAddress();

      const myUSDT = await usdt.balanceOf(account);

      const approvePidao = await usdt.allowance(
        account,
        Constants.Contract.WasaiNFT
      );
      if (
        parseFloat(utils.formatEther(approvePidao)) <=
        parseFloat(utils.formatEther(myUSDT))
      ) {
        const approve = await usdt.approve(
          Constants.Contract.WasaiNFT,
          "0xffffffffffffffffffffffffffffffffffffffff"
        );

        watchTransaction(approve.hash, (ress, boll) => {
          console.log(ress);
          console.log(ress.status);
          console.log(boll);
          if (boll) {
            console.log(ress);
            console.log(boll);
            //成功再次拉起购买
            setIsApprove("BuyBox");
            buyUseUSDT();
          }
        });

        return false;
      }

      const hash = await WasaiMint.mint(buyNumber, toAddress);

      watchTransaction(hash.hash, (ress, boll) => {
        console.log(ress);
        console.log(ress.status);
        console.log(boll);
        if (boll) {
          console.log(ress);
          console.log(boll);
          //todo 提示成功  loading不结束

          // Loading
          setLoading(false);

          toastSuccess("Successfully submit Mint!");
        }
      });
    } catch (e) {
      //报错结束  Loading
      setLoading(false);

      toastError(e?.message);
      console.error(e?.message);
    }
  };




  const openBoxSign = async (s_id, s_amount, sign, to_ddress) => {
    try {
      //开始 Loading
      setLoading(true);

      const isOk = IsBscNet();

      if (!isOk) {
        await switchToMainnet();
      }

      await connectMetamask();




      const WasaiMint = await getContractHandler("WasaiNFT");

      if (!WasaiMint) {
        console.log("加载失败");
        return;
      }

      const account = await _getAddress();

      console.log(s_id + "", s_amount, sign, account)
      const hash = await WasaiMint.mintSign(s_id + "", s_amount, sign);

      watchTransaction(hash.hash, (ress, boll) => {
        console.log(ress);
        console.log(ress.status);
        console.log(boll);
        if (boll) {
          console.log(ress);
          console.log(boll);
          //todo 提示成功  loading不结束

          // Loading
          setLoading(false);

          toastSuccess("Successfully  Mint!");
        }
      });
    } catch (e) {
      //报错结束  Loading
      setLoading(false);

      toastError(e?.message);
      console.error(e?.message);
    }
  };

  const buyUseMatic = async () => {
    try {
      const WasaiMint = await getContractHandler("WasaiMint");

      const account = await _getAddress();
      const price = await WasaiMint.TOKEN_PRICE_ETH();

      const invite_address = localStorage.getItem("invite_address")
        ? localStorage.getItem("invite_address")
        : "0x0000000000000000000000000000000000000000";
      console.log("invite_addressInwarrior", invite_address);
      await WasaiMint.purchaseTokens(buyNumber, invite_address, {
        from: account,
        value: price * buyNumber + "",
      });
      toastSuccess("Successfully submit Mint!");
    } catch (e) {
      toastError(e?.message);
      console.error(e?.message);
    }
  };

  const Changecolor = (e) => {
    console.log(e);
    setColor(e);
  };
  // const datalist = []
  // for (let i = 19; i <= 144; i++) {
  //   const imagePath = require(`./assets/avatar/${i}.png`);
  //   datalist.push({ id: i, src: imagePath.default });
  // }
  const [displayImages, setDisplayImages] = useState([]);

  useEffect(() => {
    const imageArray = [];
    for (let i = 19; i <= 144; i++) {
      const imagePath = require(`../assets/avatar/${i}.png`);
      imageArray.push({ id: i, src: imagePath });
    }

    let index = 0;
    const intervalId = setInterval(() => {
      const images = [];
      for (let i = 0; i < 4; i++) {
        images.push(imageArray[index]);
        index = (index + 1) % imageArray.length;
      }
      setDisplayImages(images);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [color]);
  return (
    <div className="page">
      <section className="tf-section project ">
        <div className="container">
          <div className="hknxqB">
            <div className="sc-iCfMLu dICrqx">
              <ul
                className="ant-menu-overflow ant-menu ant-menu-root ant-menu-horizontal ant-menu-light bg-black-40"
                role="menu"
                tabindex="0"
                data-menu-list="true"
              >
                <li
                  className={`ant-menu-overflow-item ant-menu-item ant-menu-item-only-child tab-item  ${color === "2" ? "tab-item--activated" : ""
                    }`}
                  role="menuitem"
                  tabindex="-1"
                  aria-disabled="false"
                  data-menu-id="rc-menu-uuid-71500-5-box"
                  onClick={() => Changecolor("2")}
                // style="opacity: 1; order: 1;"
                >
                  <span className="ant-menu-title-content">{t("Box")}</span>
                </li>


                <li
                  className={`ant-menu-overflow-item ant-menu-item ant-menu-item-selected ant-menu-item-only-child tab-item ${color === "1" ? "tab-item--activated" : ""
                    }`}
                  role="menuitem"
                  tabindex="-1"
                  aria-disabled="false"
                  data-menu-id="rc-menu-uuid-71500-5-marketplace"
                  onClick={() => Changecolor("1")}
                // style="opacity: 1; order: 0;"
                >
                  <span className="ant-menu-title-content">{t("Mint")}</span>
                </li>

                <li
                  className={`ant-menu-overflow-item ant-menu-item ant-menu-item-only-child tab-item ${color === "3" ? "tab-item--activated" : ""
                    }`}
                  role="menuitem"
                  tabindex="-1"
                  aria-disabled="false"
                  data-menu-id="rc-menu-uuid-71500-5-mine"
                  onClick={() => Changecolor("3")}
                // style="opacity: 1; order: 2;"
                >
                  <span className="ant-menu-title-content">{t("Special events")}</span>
                </li>


                <li
                  className="ant-menu-overflow-item ant-menu-overflow-item-rest ant-menu-submenu ant-menu-submenu-horizontal ant-menu-submenu-disabled"
                  aria-hidden="true"

                // style="opacity: 0; height: 0px; overflow-y: hidden; order: 2147483647; pointer-events: none; position: absolute;"
                >
                  <div
                    role="menuitem"
                    className="ant-menu-submenu-title"
                    aria-expanded="false"
                    aria-haspopup="true"
                    data-menu-id="rc-menu-uuid-71500-5-rc-menu-more"
                    aria-controls="rc-menu-uuid-71500-5-rc-menu-more-popup"
                    aria-disabled="true"
                  >
                    <span
                      role="img"
                      aria-label="ellipsis"
                      className="anticon anticon-ellipsis"
                    >
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="ellipsis"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M176 511a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0z"></path>
                      </svg>
                    </span>
                    <i className="ant-menu-submenu-arrow"></i>
                  </div>
                </li>
              </ul>
              <div aria-hidden="true"></div>
            </div>
          </div>
          {/* mw7 center */}
          {color === "2" ? (
            <div className={`mw7 center`}>
              <div className="">
                <video className="mw-100 db center" x5-video-player-type="h5" x5-video-player-fullscreen="true" playsinline="true" webkit-playsinline="true" x-webkit-airplay="true" autoPlay loop muted>
                  <source src={video} />
                </video>
              </div>

              <div
                className={`sc-bdvvtL TcOjT ${walletInfo.wallet === "" ? "" : "display"
                  }`}
              >
                <button
                  type="button"
                  class="ant-btn ant-btn-primary center mt2"
                >
                  <span>Connect wallet</span>
                </button>
              </div>

              <div
                className={`content-bottom ${walletInfo.wallet === "" ? "display" : ""
                  }`}
                style={{ marginTop: "20px" }}
              >
                <div className="content-progress-box style2">
                  <div
                    className="progress-bar"
                    data-percentage={parent}
                    style={{ backgroundColor: "transparent" }}
                  >
                    <p className="progress-title-holder">
                      <span className="progress-title">
                        <span className="progress_number">
                          <span className="white">{t("Total")}: </span>
                          <span className="progress-number-mark">
                            <span className="percent"></span>
                          </span>
                        </span>
                        <span> {numbe} / 100,000</span>
                      </span>
                    </p>
                    <div className="progress-content-outter">
                      <div
                        style={{ width: parent + "%" }}
                        className="progress-content"
                      ></div>
                    </div>
                  </div>
                </div>
                <div
                  className="sub-content"
                  style={{
                    display: "flex",
                    width: "100%",
                    marginTop: "20px",
                    justifyContent: "center",
                    marginBottom: "20px",
                  }}
                >
                  <RemoveIcon
                    onClick={() => {
                      if (buyNumber > 1) {
                        setBuyNumber(Number(buyNumber) - 1);
                      }
                    }}
                    cursor={"pointer"}
                    style={{ marginRight: "10px" }}
                  />
                  <Input
                    value={buyNumber}
                    style={{ width: "80%", textAlign: "center" }}
                    onChange={(e) => {
                      setBuyNumber(e.target.value);
                    }}
                  />
                  <AddIcon
                    onClick={() => {
                      setBuyNumber(Number(buyNumber) + 1);
                    }}
                    cursor={"pointer"}
                    style={{ marginLeft: "10px" }}
                  />
                </div>
                <div
                  className="sub-content"
                  style={{
                    display: "flex",
                    width: "100%",
                    marginTop: "20px",
                    justifyContent: "center",
                    marginBottom: "20px",
                  }}
                >
                  <Input value={toAddress} style={{ width: "80%" }} />
                </div>

                {isLoading ? (
                  <div
                    className="sub-content"
                    style={{
                      display: "flex",
                      width: "100%",
                      marginTop: "40px",
                      justifyContent: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <Spinner displayName="loading" iconsWidth="50px" />
                  </div>
                ) : (
                  <div
                    className="sub-content"
                    style={{
                      display: "flex",
                      width: "100%",
                      marginTop: "40px",
                      justifyContent: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <button
                      type="submit"
                      className="tf-button style2"
                      onClick={buyUseUSDT}
                    >
                      {t(isApprove)}(USDT)
                    </button>
                  </div>
                )}

                {/*    <div className="sub-content">
                <CopyAddress account={invateLink} />
              </div> */}
              </div>
            </div>
          ) : null}

          {/*  {color === "3" ? (
            <div className={`mw7 center`}>
              <div
                className="flat-tabs"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <Tabs>
                  <TabPanel className="content-tab mt40">
                    <div className="content-inner project-box-wrapper">
                      {dataTokens.map((item, index) => (
                        <ProjectItem
                          key={item._hex}
                          item={item}
                          isBox={dataTokensBox[index]}
                        />
                      ))}
                    </div>
                  </TabPanel>
                </Tabs>
              </div>

              <div className="col-md-12" data-aos="fade-up" data-aos-duration="800">
                <ul
                  className="panigation mt6"
                  
                >
                  <li>
                    {" "}
                    <span>
                      {t("Total ")}：{total}
                    </span>
                    &nbsp;&nbsp;&nbsp;
                  </li>
                  <li>
                    {" "}
                    <span> </span>
                  </li>
                  <li>
                    {" "}
                    <span>
                      {t("Total Page")} ：{Math.ceil(Number(total) / 6)}
                    </span>
                    &nbsp;&nbsp;&nbsp;
                  </li>

                  <li>
                    {" "}
                    <Input
                      onChange={changeNumber}
                      value={nowpageV}
                      style={{ width: "60px" }}
                    />{" "}
                  </li>
                  <li>
                    {" "}
                    <Button
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        if (
                          nowpageV &&
                          nowpageV > 0 &&
                          nowpageV <= Math.ceil(Number(total))
                        ) {
                          setNowPage(nowpageV);
                          setStart((nowpageV - 1) * 6);
                          setEnd(nowpageV * 6);
                        }
                      }}
                    >
                      {t("Jump")}
                    </Button>
                  </li>
                
                </ul>
              </div>
            </div>
          ) : null} */}

          {/* 特别活动 */}
          {color === "3" ? (
            
 
               <Blog />

           
          ) : null}
          <div style={{ height: "5px" }}></div>
          {color === "1" ? (
            <div>
              <div className={`pa3`}>
                <div className="sc-hBUSln CkOuA">
                  <div
                    className="ant-row ant-row-space-around"
                    style={{ marginRight: "-60px", rowGap: "0px" }}
                  >
                    <div
                      className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-8 ant-col-lg-6"
                      style={{ paddingRight: "60px" }}
                    >
                      <div className="sc-fFeiMQ cbwZeg">
                        <div className="sc-kDTinF kSxRYG">
                          {/* <video
                        autoPlay
                        loop
                        muted
                        className="bg-full"
                        playsInline
                      >
                        <source src={video} />
                      </video> */}
                        </div>
                      </div>
                    </div>
                    <div
                      className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-18"
                      style={{ paddingRight: "60px" }}
                    >
                      <div
                        className="ant-row text-bold f2 mt3"
                        style={{
                          marginLeft: "-5px",
                          marginRight: "-5px",
                          rowGap: "0px",
                          marginBottom: "20px",
                        }}
                      >
                        W.NFT
                      </div>
                      <div
                        className="ant-row text-sm f7"
                        style={{
                          marginLeft: "-5px",
                          marginRight: "-5px",
                          rowGap: "0px",
                        }}
                      >
                        Blind boxes completed in the early pre-sale stage are extracted through signature
                      </div>
                      <div
                        className="ant-row ant-row-space-around text-sm mt3"
                        style={{
                          marginLeft: "-20px",
                          marginRight: "-20px",
                          rowGap: "0px",
                        }}
                      >



                      </div>
                    </div>
                  </div>
                </div>
                <div className="sc-ksdxgE bxscme">
                  <img
                    className="bg"
                    src="https://killnet.babyloser.com/static/media/bg-nft.ae47f6ff.png/"
                    alt=""
                  />
                  <div className="flex justify-end"></div>
                  <div
                    className="ant-row"
                    style={{
                      marginLeft: "-8px",
                      marginRight: "-8px",
                      rowGap: "0px",
                    }}
                  >
                    {/* <div className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-8 ant-col-lg-6" style={{ paddingLeft: "8px", paddingRight: "8px",width:'240px' }}>
            <div className="sc-kDTinF kSxRYG">
            <img className="" src="http://localhost:3000/static/media/73.ebeae71d490262ca7889.png" alt="" />
            </div>
          </div> */}
                    {displayImages.map((item) => (
                      <div
                        className="ant-col ant-col-xs-24 ant-col-sm-12 ant-col-md-8 ant-col-lg-6 "
                        data-aos="fade-up"
                        data-aos-duration="2000"
                        style={{
                          paddingLeft: "8px",
                          paddingRight: "8px",
                          width: "240px",
                        }}
                      >
                        <div className="sc-kDTinF kSxRYG">
                          <img className="" src={`${item.src} `} alt="" />
                        </div>
                      </div>
                    ))}
                    <section
                      className="tf-section project_2"
                      style={{ marginLeft: "-5px" }}
                    >
                      <div className="container">
                        <div className="row">
                          {/* <div className="col-md-12">
                            <div className="tf-title left mb40" data-aos="fade-up" data-aos-duration="800">
                                <h2 className="title">
                                    Completed pools
                                </h2>
                            </div>
                        </div> */}
                          <div className="col-md-12">
                            <div className="project_wrapper_2" >
                              {data.map((item) => (
                                <div
                                  key={item.id}
                                  className="project-box-style7"
                                >
                                  {/* <div className="image">
                                                <img src={item.img} alt="" /> 
                                            </div> */}
                                  <div className="content" style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
                                    <h5 className="heading">
                                      <Link to="/project_details">
                                        {item.title}
                                      </Link>
                                    </h5>
                                    <ul>
                                      <li>
                                        <p>Number</p>
                                        <p>{item.number}</p>
                                      </li>
                                      <li>
                                        <p>Time</p>
                                        <p>{item.create_time}</p>
                                      </li>
                                      <li>
                                        <p>Address</p>
                                        <p>{item.to_address.substr(0, 8) + '...' + item.to_address.substr(-4)}</p>
                                      </li>
                                      <li>
                                        <p>status</p>
                                        <p>
                                          {item.status == 1
                                            ? t("unclaimed")
                                            : t("claimed")}
                                        </p>
                                      </li>
                                    </ul>
                                    <Button
                                      variant={item.status == 1 ? "primary" : "subtle"}
                                      style={{ marginLeft: "10px" }}
                                      onClick={() => {
                                        if (item.status == 1) {
                                          openBoxSign(item.id, item.number, item.sign, item.to_address)
                                        }

                                      }}
                                    >
                                      {t("Receive")}
                                    </Button>
                                    <div className="bg_style">
                                      <div className="image_inner">
                                        <img
                                          src={require("../assets/images/backgroup/bg_project_2.png")}
                                          alt=""
                                        />
                                        <div className="icon">
                                          <svg
                                            width="12"
                                            height="18"
                                            viewBox="0 0 12 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M11.6686 11.2128L6.58857 17.7045C6.51931 17.7947 6.43036 17.8682 6.32841 17.9194C6.22647 17.9705 6.11418 17.998 6 17.9998V13.0757L11.2229 10.6562C11.2978 10.6208 11.3826 10.6117 11.4634 10.6303C11.5442 10.6489 11.6164 10.6941 11.668 10.7586C11.7197 10.8231 11.7479 10.9031 11.748 10.9856C11.7481 11.0681 11.7201 11.1481 11.6686 11.2128Z"
                                              fill="white"
                                            />
                                            <path
                                              d="M6.00091 13.0757V17.9998C5.88672 17.998 5.77444 17.9705 5.67249 17.9194C5.57055 17.8682 5.4816 17.7947 5.41234 17.7045L0.332327 11.2128C0.280814 11.1481 0.252831 11.0681 0.25293 10.9856C0.253029 10.9031 0.281205 10.8231 0.332873 10.7586C0.384541 10.6941 0.456658 10.6489 0.537488 10.6303C0.618318 10.6117 0.703103 10.6208 0.778042 10.6562L6.00091 13.0757Z"
                                              fill="white"
                                            />
                                            <path
                                              d="M5.99947 6.97485V11.9161C5.93001 11.918 5.8612 11.9023 5.79947 11.8706L0.26803 9.2126C0.205378 9.18093 0.150426 9.13608 0.107002 9.08115C0.0635789 9.02622 0.032729 8.96253 0.0166016 8.89454L5.99947 6.97485Z"
                                              fill="white"
                                            />
                                            <path
                                              d="M5.99993 8.36809e-08V6.97448L0.0170624 8.89417C-0.00103379 8.82816 -0.00478768 8.75907 0.00604991 8.6915C0.0168875 8.62393 0.0420677 8.55943 0.0799189 8.50228L5.3885 0.340773C5.45248 0.23664 5.54231 0.150604 5.64939 0.0909285C5.75646 0.0312526 5.87717 -5.90778e-05 5.99993 8.36809e-08V8.36809e-08Z"
                                              fill="white"
                                            />
                                            <path
                                              d="M11.9829 8.89417L6 6.97448V8.36809e-08C6.12276 -5.90778e-05 6.24347 0.0312526 6.35054 0.0909285C6.45762 0.150604 6.54745 0.23664 6.61143 0.340773L11.92 8.50228C11.9579 8.55943 11.983 8.62393 11.9939 8.6915C12.0047 8.75907 12.001 8.82816 11.9829 8.89417V8.89417Z"
                                              fill="white"
                                            />
                                            <path
                                              d="M11.9829 8.89454C11.9667 8.96253 11.9359 9.02622 11.8925 9.08115C11.849 9.13608 11.7941 9.18093 11.7314 9.2126L6.2 11.8706C6.13827 11.9023 6.06946 11.918 6 11.9161V6.97485L11.9829 8.89454Z"
                                              fill="white"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          {/* <div className="col-md-12"> 
                                <div className="wrap-btn justify-content-center">
                                    <Link to="/project_details" className="tf-button style1">
                                        Show more
                                    </Link>
                                </div> 
                        </div> */}
                        </div>
                      </div>
                    </section>
                    {/* Add more video elements here as needed */}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </section>
    </div>
  );
}

export default Project1;
