/*
 * @Author: ahsdxy 2729945316@qq.com
 * @Date: 2024-05-06 12:14:32
 * @LastEditors: ahsdxy 2729945316@qq.com
 * @LastEditTime: 2024-05-09 23:34:07
 * @FilePath: \wasai-nft-web\src\components\pagehtml\CollectionSection.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import '../../assets/images/favicon.png';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/aos.css';
import '../../assets/css/all.min.css';
import '../../assets/css/lightcase.css';
import '../../assets/css/swiper-bundle.min.css';
import '../../assets/css/style.css';
import svg from "../../assets/images/opensea.svg";
import { useTranslation } from '../../utils/contexts/Localization'

import { Constants } from '../../utils/Config'

function CollectionSection() {
  const { t } = useTranslation()
  return (
    <section className="collection padding-top padding-bottom">
      <div className="container">
        <div className="collection__wrapper">
          <div className="row g-4">
            <div className="col-lg-3">
              <div className="collection__header">
                <div className="collection__header-content">
                  <p className="subtitle">{t('Collection')}</p>
                  <h2>{t('wasai apes NFT items')}</h2>
                  <p>{t('100000 Unique Undercover Creatures Start Minting')}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="swiper collection__slider1">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="collection__item">
                      <div className="collection__item-thumb"><img src={require('../../assets/images/collection/73.png')} alt="NFT Image" /></div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="collection__item">
                      <div className="collection__item-thumb"><img src={require('../../assets/images/collection/74.png')} alt="NFT Image" /></div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="collection__item">
                      <div className="collection__item-thumb"><img src={require('../../assets/images/collection/78.png')} alt="NFT Image" /></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="swiper collection__slider2">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="collection__item">
                      <div className="collection__item-thumb"><img src={require('../../assets/images/collection/27.png')} alt="NFT Image" /></div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="collection__item">
                      <div className="collection__item-thumb"><img src={require('../../assets/images/collection/28.png')} alt="NFT Image" /></div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="collection__item">
                      <div className="collection__item-thumb"><img src={require('../../assets/images/collection/29.png')} alt="NFT Image" /></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="collection__btn">
                <a href={Constants.OPENSEA_URL} className="default-btn default-btn--secondary">
                  <span><img src={svg} alt="opensea icon" width="24" height="24" /> {t('View On Opensea')}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CollectionSection;
