/*
 * @Author: ahsdxy 2729945316@qq.com
 * @Date: 2024-05-06 12:05:32
 * @LastEditors: ahsdxy 2729945316@qq.com
 * @LastEditTime: 2024-05-14 20:07:51
 * @FilePath: \wasai-nft-web\src\components\pagehtml\HomeBanner.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import '../../assets/images/favicon.png';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/aos.css';
import '../../assets/css/all.min.css';
import '../../assets/css/lightcase.css';
import '../../assets/css/swiper-bundle.min.css';
import '../../assets/css/style.css';
import { NavLink } from 'react-router-dom';
import { useTranslation } from '../../utils/contexts/Localization'
function HomeBanner() {
  const { t } = useTranslation()
  return (
    <section id="home" className="banner" style={{backgroundImage: "url(" + require('../../assets/images/banner/bg.png') + ")" }}>
      <div className="container">
        <div className="banner__wrapper">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <div className="banner__content" data-aos="fade-right" data-aos-duration="2000">
                <h3>Our Mint is <span className="color--secondary-color">Live <i className="fa-solid fa-wifi"></i></span></h3>
                <h1><span className="color--theme-color">Wasai <br /> NFT</span></h1>
                <p>{t("New Wow Planet, NFT for Everything")}</p>
                <div className="btn-group">
                <NavLink to="/project_v1">
                <a className="default-btn default-btn--secondary">{t('Mint Now')}</a>
                  </NavLink>
                  {/* <a href="#" className="default-btn default-btn--secondary">Mint Now</a> */}
                  <NavLink to="/project_v1">
                  <a href="#" className="default-btn">{t('Whitelist Now')}</a>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner__thumb d-flex justify-content-center" data-aos="fade-left" data-aos-duration="2000">
                <img src={require('../../assets/images/banner/01.png')} alt="banner Image" />
                <a className="banner__video"  data-rel="lightcase">
                  <div className="banner__video-inner">
                    <svg viewBox="0 0 100 100">
                      <defs>
                        <path id="circle" d="M 50, 50 m -37, 0 a 37,37 0 1,1 74,0 a 37,37 0 1,1 -74,0" />
                      </defs>
                      <text>
                        <textPath xlinkHref="#circle">
                          Mint is live * Mint is live * Mint Is live
                        </textPath>
                      </text>
                    </svg>
                    <span><i className="fa-solid fa-play"></i></span>
                  </div>
                </a>
                <div className="banner-shape">
                  <img src={require('../../assets/images/banner/icon/01.png')} alt="shape" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeBanner;
