/*
 * @Author: ahsdxy 2729945316@qq.com
 * @Date: 2024-04-13 12:36:32
 * @LastEditors: ahsdxy 2729945316@qq.com
 * @LastEditTime: 2024-05-09 22:28:25
 * @FilePath: \wasai-nft-web\src\App.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { React, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import AOS from 'aos';
import routes from './pages';
import '../src/assets/fonts/font-awesome.css'
import Header from './components/header';
import Footer from './components/footer';
import { ThemeProvider } from 'styled-components'
import { dark } from '@pancakeswap/uikit'
import { ToastListener, ToastsProvider } from './utils/contexts/ToastsContext'
import { LanguageContext, LanguageProvider } from './utils/contexts/Localization/Provider'
import './App.scss'
import { parse } from 'querystringify'
import './i18n';
const StyledThemeProvider = (props) => {
  return <ThemeProvider theme={dark} {...props} />
}


function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, []);



  
  const getPageQuery = () => parse(window.location.href.split('?')[1])

  const locationQuery = getPageQuery()
  if (locationQuery && locationQuery.invite) {
    // 读取base64字符串
    
    // 进行解码
    localStorage.setItem('invite_address', locationQuery.invite.toString())
    
  }

  if (locationQuery && locationQuery.address) {
    // 读取base64字符串
    // 进行解码
    console.log('locationQuery:', locationQuery); // 添加这行来调试  
    console.log('other_address66666666:', locationQuery.address.toString()); // 添加这行来调试  

    localStorage.setItem('other_address', locationQuery.address.toString())
    console.log('other_address:', localStorage.getItem('other_address')); // 添加这行来调试  
    
    localStorage.setItem('other_token', locationQuery.token.toString())

  }

 

  return (
    <>
   
    <LanguageProvider>
        <ToastsProvider>
          <StyledThemeProvider>
            <Header />

            <Routes>

              {
                routes.map((data, idx) => (
                  <Route key={idx} path={data.path} element={data.component} exact />
                ))
              }

            </Routes>


            {/* <Footer /> */}
            <ToastListener />
            
          </StyledThemeProvider>
        </ToastsProvider>
      </LanguageProvider>
    </>
  );
}

export default App;