import React from 'react'

import PageTitle from '../components/pagetitle'
import CTA from '../components/cta/cta_v2'

function Contact(props) {

  let list = [];
  for (let index = 1800; index < 1900; index++) {
    list.push("https://metainfo.wasai.space/nft/images/"+index+".png"); 
  }
  return (
    <div className="inner-page">
      


     {
       list.map((item,index)=><img key={index+"imte"} width={300} height={300} src={item} />)
     }
 

      
      {<CTA />}
    </div>
  )
}

export default Contact
