import React from 'react'
import '../scss/component/_section.scss'
import '../scss/component/_box.scss'
import '../scss/component/_tf-section.scss'
import '../scss/component/_reponsive.scss'
// import Banner from '../components/banner/banner_v1'
// import dataBanner from '../assets/fake-data/data-banner'
// import Project from '../components/project/project_v1'
  import Project2 from '../components/project/project_v2'
// import dataProject from '../assets/fake-data/data-project'
// import Project3 from '../components/project/project_v3'
// import Project4 from '../components/project/project_v4'
// import Token from '../components/token'

// import Team from '../components/team'
// // import Partner from '../components/partner'
// import dataPartner from '../assets/fake-data/data-partner'
// import CTA from '../components/cta'
// import dataTeam from '../assets/fake-data/data-team'
// import home from '../components/project/home'

// import Header from '../components/pagehtml/header.jsx';
// import Hero from '../components/pagehtml/Hero.jsx';
// import Features from '../components/pagehtml/FeaturedFeatures.jsx';
import FAQ from '../components/pagehtml/FAQSection.jsx';
import Blog from '../components/pagehtml/BlogSection.jsx';
import Partner from '../components/pagehtml/PartnerSection.jsx';
import Community from '../components/pagehtml/CommunitySection.jsx';
import Footer from '../components/pagehtml/Footer.jsx';
import WalletModel from '../components/pagehtml/WalletModal.jsx'
import HomeBanner from '../components/pagehtml/HomeBanner.jsx';
import Counter from '../components/pagehtml/Counter.jsx';
import AboutSection from '../components/pagehtml/AboutSection.jsx';
import Radmap from '../components/pagehtml/RoadmapSection.jsx'
import CollectionSection from '../components/pagehtml/CollectionSection.jsx';
import Team from '../components/pagehtml/TeamSection.jsx'
function HomeOne(props) {
  return (
  <div>
    {/* <Header /> */}
      {/* <Hero /> */}
      {/* <Features /> */}
      <WalletModel />
      <HomeBanner />
      <Counter />
      <AboutSection />
      <Radmap></Radmap>
      <CollectionSection></CollectionSection>
      <Team></Team>
      <FAQ />
      <Blog />
      <Partner />
      <Community />
      <Footer />
  </div>
    // <div className="header-fixed main home1 counter-scroll">
     
    //   {/* {<Banner data={dataBanner} />} */}
    //   {/* {<Project data={dataProject} />} */}
    //     {/* {<Project2 data={dataProject} />} */}
    //   {/* {<Project3 data={dataProject} />} */}

    //   {/*  {<Token />} */}

    //   {/* <Project4 data={dataProject} /> */}

    //   {/* <Team data={dataTeam} /> */}

    //   {/* <Partner data={dataPartner} /> */}

    //   {/* <CTA /> */}
    //   {/* {<home></home>} */}
    // </div>
  )
}

export default HomeOne
